/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { isEmpty, keys } from 'lodash';
import { Form, Input, Select } from 'antd';
import ChannelRow, { ChannelHeader } from './ChannelRow';
import { getMapFromArr, regexMatch } from '../../../utils/helpers';
import { getDefaultSpeedAttribute } from '../../../models';
import { getChannelRows, getChannelsFormData } from '../../../utils/device';
import SerialPreview from './SerialPreview';
import { DeviceConstants as K } from '../../../../store/old/Devices/Devices.constants';

const CHANNELS_VALIDATE = {
    CMD: [
        (form) => ({
            validator: (_, value) => {
                const dmode = form.getFieldValue('dmode');
                if (dmode === K.DMODE_PUSH) return Promise.resolve();
                if (!value.trim())
                    return Promise.reject(new Error('Command cannot be empty'));
                return Promise.resolve();
            },
        }),
    ]
};


const Rs232Form = forwardRef((props, ref) => {
    const [form] = Form.useForm();

    const [preview, setPreview] = useState('');
    const [serialMap, setSerialMap] = useState(props.device.channelSerialMap);
    const [serialDmode, setSerialDmode] = useState(props.device.dmode);

    const isPoll = serialDmode === 'poll';
    const hideCmd = !isPoll;

    const channelRows = useMemo(() => getChannelRows(keys(serialMap).length), [preview, serialMap]);

    const channels = getMapFromArr(props.data, 'channel');

    const getSerialPreviewData = async () => {
        try {
            await form.validateFields();
            const errors = form.getFieldsError().filter(item => item.errors.length);

            if (errors.length) {
                return undefined;
            }

            const data = form.getFieldsValue(true);

            if (isPoll) return { cmd: data.cmd };
            return {};
        } catch (e) {}   
    };

    useEffect(() => {
        if (!preview) return;

        const data = form.getFieldsValue(true);

        const previewResult = preview.match(K.REGEX_OPTIONS[0].rawLabel) || [];

        const _serialMap = previewResult.reduce(
            (acc, curr, index) => ({
                ...acc,
                [index]: [isPoll ? data.cmd : '-', +curr],
            }),
            {}
        );

        setSerialMap(_serialMap);
    }, [preview]);

    useEffect(
        () => props.onChange && props.onChange(getFormData(form.getFieldsValue(true))),
        [channelRows]
    );

    const getSerialOrder = (metadata) => {
        return metadata.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.channel]: { ch: `${curr.channel}`, mode: curr.mode },
            }),
            {}
        );
    };

    const getSerialData = (formData, metadata) => {
        const result = {
            dmode: formData.dmode,
        };

        const data = {
            regex: K.REGEX_OPTIONS[0].label,
            map: getSerialOrder(metadata),
        };

        if (formData.dmode !== K.DMODE[1].value) {
            data.cmd = { enc: 'ascii', val: formData.cmd };
        }

        result.data = [data];

        return result;
    };

    const getFormData = (data) => {
        const metadata = getChannelsFormData(data);
        const serial = getSerialData(data, metadata);

        return { metadata, serial };
    }

    useImperativeHandle(ref, () => ({
        getFormData() {
            return getFormData(form.getFieldsValue(true));
        },
        async validateFields() {
            try {
                await form.validateFields();
            } catch {}
        },
        getFormErrors() {
            return form.getFieldsError().filter(item => item.errors.length)
        }
    }));

    const isChangedChannel = (obj) => {
        const [channel] = keys(obj).filter((k) => regexMatch(k, /\d/g));
        return channel;
    };

    return (
        <Form
            ref={ref}
            name="rs232Form"
            form={form}
            className="d-flex w-100 h-100 flex-column justify-content-between"
            initialValues={{ remember: true }}
            onValuesChange={(changedValues) => {
                if (changedValues.dmode) setSerialDmode(changedValues.dmode);

                const channel = isChangedChannel(changedValues);
                if (channel && changedValues[channel].mode) {
                    form.setFields([
                        {
                            name: [channel, 'speed'],
                            value: getDefaultSpeedAttribute(changedValues[channel].mode),
                        },
                    ]);
                }

                props.onChange && props.onChange(getFormData(form.getFieldsValue(true)));
            }}
            hidden={props.hidden}
            preserve
            key={props.data.length}
        >
            <Form.Item noStyle name="type" initialValue={K.RS232} hidden={true}>
                <Input disabled />
            </Form.Item>
            <Form.Item
                name="dmode"
                label="Poll / Push"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValue={props.device.dmode || 'poll'}
            >
                <Select className="w-100" options={K.DMODE} />
            </Form.Item>
            <Form.Item
                name="cmd"
                label="Command (Poll)"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValue={props.device.cmd}
                hidden={hideCmd}
                rules={CHANNELS_VALIDATE.CMD}
            >
                <Input.TextArea className="w-100" maxLength={50} />
            </Form.Item>
            <SerialPreview 
                device={props.device}
                onChange={setPreview}
                getDataForPreview={getSerialPreviewData}
            />
            <hr style={{ width: '60%' }} />
            <ChannelHeader hideMapping={isEmpty(serialMap)} />
            {channelRows.map((c) => (
                <Form.Item noStyle name={`${c}`} key={c}>
                    <ChannelRow
                        form={form}
                        key={c}
                        data={channels[+c]}
                        channel={+c}
                        serialMap={serialMap}
                        isSerial={true}
                        handleDelete={() => {
                            form.setFields([
                                { name: [`${c}`, 'chart_title'], value: '', errors: [] },
                                { name: [`${c}`, 'mode'], value: null, errors: [] },
                                { name: [`${c}`, 'batRec', 'upp'], value: 99999, errors: [] },
                                { name: [`${c}`, 'batRec', 'low'], value: 0.001, errors: [] },
                                { name: [`${c}`, 'batRec', 'in'], value: 1, errors: [] },
                                { name: [`${c}`, 'batRec', 'out'], value: 1, errors: [] },
                            ]);
                            props.onChange && props.onChange(getFormData(form.getFieldsValue(true)));
                        }}
                    />
                </Form.Item>
            ))}
        </Form>
    );
});

export default Rs232Form;
