import React, { useRef } from "react"
import ChartWrapper from "../../../components/Chart/ChartWrapper"
import { ColumnChart, LabelsChart } from "../../../legacy/Charts/v1"
import * as d3 from "d3"
import { ColumnChartTooltip, LabelsChartTooltip } from "../../../legacy/Charts/v1/tooltips/templates"
import { labelDisplayText } from "../../../legacy/utils/labels"
import { Label } from "../../../legacy/models"
import { cancelBrushSelection, generateContinuousDomain, getLabelBrushRange, moveBrushSelection } from "../../../legacy/utils/charts"
import { generateId, getDataSubarrByTime } from "../../../legacy/utils/helpers"
import { AnyFunction } from "../../../types/any"

const OutputChart = (props: Props) => {
    const chartId = useRef(`op-${generateId()}`)

    const outputData = props.data ? props.data.output : []
    const labelsData = props.data ? props.data.labels : []

    const getBrushedData = (brushedRange: Date[]) => {
        return getDataSubarrByTime(
            props.data.output,
            brushedRange[0],
            brushedRange[1]
        )
    }

    const handleLabelClick = (d: any, x: any) => {
        cancelBrushSelection(d3.selectAll(".brush"))
        const chartBrush = d3.select(`#${chartId.current}`).select(".brush")
        const brushedRange = getLabelBrushRange(d, props.xDomain, { toAccessor: (l: Label) => l._to })
        moveBrushSelection(chartBrush, brushedRange.map(x))
        props.onLabelClick && props.onLabelClick(d, brushedRange, getBrushedData(brushedRange))
    }

    return <ChartWrapper height={props.height || "100%"} id={chartId.current}>
        <div style={{ height: 18, flexShrink: 0 }}>
            <LabelsChart
                margin={{ top: 0, left: 35, right: 20, bottom: 0 }}
                xScale={d3.scaleTime()}
                xDomain={props.xDomain}
                xAccessor={(d: Label) => new Date(d.from)}
                x2Accessor={(d: Label) => new Date(d._to)}
                data={labelsData}
                colorAccessorLabel={(d: Label) => props.labelsEditable ? d.color : "#616161"}
                colorAccessorText={() => "#fafafa"}
                textAccessor={labelDisplayText}
                useTooltip={true}
                htmlTooltip={LabelsChartTooltip}
                keyAccessor={(d: Label) => d.label_id}
                className="w-100"
                onDoubleClick={(d: any, x: any) => { handleLabelClick(d, x) }}
            />
        </div>
        <ColumnChart
            margin={{ top: 10, left: 35, right: 15, bottom: 20 }}
            xScale={d3.scaleTime()}
            yScale={d3.scaleLinear()}
            xDomain={props.xDomain}
            yDomain={generateContinuousDomain(outputData, (d: OutputDatum) => d.val)}
            data={outputData}
            xAccessor={(d: OutputDatum) => d.time}
            yAccessor={(d: OutputDatum) => d.val}
            htmlTooltip={ColumnChartTooltip}
            onBrushEnd={(bounds: any, scaled: any, brushElement: any, data: any) => {
                props.onBrushEnd && props.onBrushEnd(bounds, scaled, brushElement, data)
            }}
            onBrushCancel={props.onBrushCancel} // this closes drawer in parent
            useBrush
        />
    </ChartWrapper>
}

interface Props {
    data: ChartData;
    height: string | number
    labelsEditable?: boolean
    xDomain: Date[]
    onLabelClick?: AnyFunction
    onBrushEnd?: AnyFunction
    onBrushCancel?: AnyFunction
}

interface ChartData {
    labels: Label[],
    output?: OutputDatum[]
}

interface OutputDatum {
    time: Date;
    val: number;
    int: number;
}

export default OutputChart
