/* eslint-disable react/prop-types */
import React from 'react';
// import Table from '../../components/Table';
import { Permission } from '../../components/Permission';
import translate from '../../utils/translate';
import { Table } from 'antd';

class Groups extends React.Component {
    render() {
        const { groupList, handleClickOnEdit } = this.props;
        const EditButton = ({ id }) => {
            return (
                <div>
                    <div onClick={handleClickOnEdit.bind(this, id)}>
                        <i className="fas fa-edit" />
                    </div>
                </div>
            );
        };

        function fillUsers(users) {
            return users.map(({ user }) => {
                return `${user.name_first} ${user.name_last}`;
            });
        }

        return (
            <Table
                style={{ marginTop: '0.5em' }}
                rowKey="group_id"
                dataSource={groupList}
                columns={[
                    {
                        title: translate('groups'),
                        render: (text, record, index) => record.group_name,
                        width: 200,
                    },
                    {
                        title: translate('members'),
                        accessor: 'users',
                        render: (text, record, index) => (
                            <div className="users-cursive qwe">
                                {record.user_groups.length <= 3 ? (
                                    fillUsers(record.user_groups).join(', ')
                                ) : (
                                    <MoreLess
                                        array={fillUsers(record.user_groups)}
                                    />
                                )}
                            </div>
                        ),
                        // width: 600,
                        sortable: false,
                    },
                    {
                        title: translate('total'),
                        render: (text, record, index) => record.user_groups.length,
                        width: 100,
                    },
                    {
                        accessor: 'us',
                        render: (text, record, index) => (
                            <Permission forResource resource="users" canDo="edit">
                                <EditButton id={record.group_id} />
                            </Permission>
                        ),
                        width: 60,
                        sortable: false,
                    },
                ]}
                pagination={10}
                className="user-groups-table"
            />
        );
    }
}

class MoreLess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            items: this.props.array,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            isOpen: false,
            items: props.array,
        });
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    getRenderedItems() {
        if (this.state.isOpen) {
            return this.state.items;
        }
        return this.state.items.slice(0, 3);
    }

    render() {
        const { isOpen } = this.state;
        return (
            <div className="gr-list-users">
                {this.getRenderedItems().map((item, id) => (
                    <div key={id} className="gr-list-users-inline">
                        {id !== this.getRenderedItems().length - 1 ? item + ', ' : item}
                    </div>
                ))}
                {isOpen ? (
                    <span className="moreLess">
                        <a onClick={this.toggle}>show less</a>
                    </span>
                ) : (
                    <span className="moreLess">
                        <a onClick={this.toggle}>show more</a>
                    </span>
                )}
            </div>
        );
    }
}

export default Groups;
