import "./BlockRow.scss"

import { Collapse } from "antd"
import React, { useMemo } from "react"
import WithBlockUsers from "../../../legacy/routes/Blocks/WithBlockUsers"
import BlockModel from "../../../api/models/block.model"
import useLegacyBlock from "../../../hooks/useLegacyBlock"
import { OEEDials } from "../../../legacy/Widgets"
import { useAppSelector } from "../../../store/hooks"

const BlockRow = (props: Props) => {
    const legacyBlock = useLegacyBlock(props.block.blockId)
    const oee2 = useAppSelector(appState => appState.ui.controls.oee2)
    const blocksOee = useAppSelector(appState => appState.data.blocks)

    const data = useMemo(() => {
        const blockOee = blocksOee[props.block.blockId]

        if (!blockOee) return {
            final_effective: 0,
            loading: 0,
            availability: 0,
            performance: 0,
            quality: 0,
        }
        return {
            final_effective: blockOee.overall.final_effective,
            loading: blockOee.overall.loading,
            availability: blockOee.overall.availability,
            performance: blockOee.overall.performance,
            quality: blockOee.overall.quality,
        }
    }, [blocksOee])

    return (
        <Collapse ghost defaultActiveKey={props.block.blockId}>
            <Collapse.Panel
                key={props.block.blockId}
                header={
                    <WithBlockUsers block={legacyBlock}>
                        {props.header}
                    </WithBlockUsers>
                }
            >
                <div className="block-row">
                    {props.chart}
                    <OEEDials
                        display="row"
                        data={data}
                        oee2={oee2}
                        donutProps={{
                            fontSize1: 12,
                            fontSize2: 10,
                        }}
                    />
                </div>
            </Collapse.Panel>
        </Collapse>
    )
}

interface Props {
    block: BlockModel
    header: React.ReactNode
    chart?: React.ReactNode
    data?: any;
}

export default BlockRow