/* eslint-disable react/prop-types */


// LIBRARIES
import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';

// COMPONENTS
import DeviceConfiguration from '../../Devices/DeviceConfiguration';

// REDUX
import { updateDevice as _updateDevice } from '../../../../store/old/Devices/Devices.action';

import './AssetDevices.scss';
import { userSelector } from '../../../../store/old/Authentication/Authentication.selector';
import LinkFlow from '../../Devices/DeviceInitialize';
const { TabPane } = Tabs;

let linkIndex = -1;

const AssetDevices = (props) => {
    const authUser = useSelector(userSelector);

    const onUnlinkDevice = () => {
        setActiveKey(props.devices.length ? `${props.devices[0].device_id}` : null);
    };

    const [linkSelection, setLinkSelection] = useState([]);
    const [activeKey, setActiveKey] = useState(
        props.devices.length ? `${props.devices[0].device_id}` : null
    );

    const add = () => {
        setLinkSelection(
            linkSelection.concat({ title: 'Link New Device', key: linkIndex })
        );
        setActiveKey(`${linkIndex}`);
        linkIndex--;
    };

    const remove = (targetKey) => {
        setLinkSelection(linkSelection.filter((s) => s.key !== +targetKey));
    };

    const onEdit = (targetKey, action) => {
        if (action === 'add') add();
        if (action === 'remove') remove(targetKey);
    };

    const hasDevicesEditAccess = authUser.check_resource_policy(
        'devices',
        false,
        true,
        false
    );

    console.log(props.devices, activeKey)

    return (
        <Tabs
            tabPosition="left"
            type={hasDevicesEditAccess ? 'editable-card' : 'card'}
            tabBarStyle={{ width: 200 }}
            destroyInactiveTabPane
            onEdit={onEdit}
            onChange={setActiveKey}
            activeKey={activeKey}
            className="asset-devices"
        >
            {props.devices.map((d) => (
                <TabPane
                    tab={<span title={d.mac_address}>{d.mac_address}</span>}
                    key={d.device_id}
                    closable={false}
                >
                    <DeviceConfiguration
                        interface={d.interface_type}
                        device={d}
                        onUnlink={onUnlinkDevice}
                    />
                </TabPane>
            ))}
            {linkSelection.map((s) => (
                <TabPane tab={s.title} key={s.key} closable>
                    <div className="w-100 h-100 d-flex">
                        <LinkFlow
                            asset={props.asset}
                            onSubmit={(updated) => {
                                setActiveKey(`${updated.device_id}`);
                                remove(s.key);
                            }}
                        />
                    </div>
                </TabPane>
            ))}
        </Tabs>
    );
};

export default AssetDevices;
