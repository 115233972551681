import { BlockConstants as K } from './block.constants'
import { resetBlockStore, setBlockIssueLabels, setBlockOeeData, setBlockOutputData, setBlockSkuLabels } from './block.action'
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { store } from '../../..';
import { batchBlockOee } from '../../Data/Data.helpers';
import { errorFlash } from '../../../../legacy/components/Flash';
import { currentEntitySelector } from '../../Entity/Entity.selector';
import { api_createLabel, api_getAssetLabels } from '../../Labels/Labels.services';
import { api_getBlockOutput } from '../../Blocks/Blocks.services';
import { TimeSeriesFactory } from '../../../../legacy/utils/data';
import { Label, parseLabelArguments } from '../../../../legacy/models';
import { addBlocksOeeDataResource } from '../../Data/Data.action';

function* handleGetBlockPageOee(action) {
    try {
        const { blockId: block_id, query, options } = action.payload

        const appState = store.getState();
        const entity = currentEntitySelector(appState)

        let { oee, labels } = yield* batchBlockOee({ entity, block_id, query, options });

        if (options.withLabels) {
            const blocks = appState.blocks.blocks;
            const block = blocks[block_id];

            if (block.children) {
                const assets = block.children.filter(child => child.asset_id)
                const assetsLabelsSeries = yield all(
                   assets.map(a => call(api_getAssetLabels, a.asset_id, {
                        types: ["issue"],
                        date_range: query.date_range,
                    }))
                )
                const assetsLabels = assets.reduce((acc, curr, i) => {
                    return {...acc, [curr.block_id]: assetsLabelsSeries[i].map(l => new Label(...parseLabelArguments(l)))}
                }, {})

                labels = { ...labels, ...assetsLabels }
            }
        }

        yield all([
            put(addBlocksOeeDataResource(oee)),
            // put(setBlockOeeData(oee)), // hotfix: stop using ui store for block page oee
            put(setBlockIssueLabels(labels))
        ])

        action.callback && action.callback({ oee: blocksOeeResource, labels: labels });
    } catch (error) {
        errorFlash(error);
    }
}

export function* getBlockOeeDataSaga() {
    yield takeLatest(
        K.ACTIONS.BLOCK_FETCH_OEE_DATA,
        handleGetBlockPageOee
    );
}

function* handleGetBlockPageOutput(action) {
    try {
        const { blockId: block_id, query, options } = action.payload

        const appState = store.getState();
        const entity = currentEntitySelector(appState)
        const block = appState.blocks.blocks[block_id]

        const assets = block.children.filter(child => child.asset_id);
        const blocks = [block].concat(...block.children);

        const [outputSeries, labelsSeries] = yield all([
            all(blocks.map(b => call(api_getBlockOutput, entity.entity_id, b.block_id, {
                starts_at: query.date_range.lower.toISOString(),
                ends_at: query.date_range.upper.toISOString(),
                resolution: query.res_x,
                period: query.res_period
            }))),
            options.withLabels ? all(assets.map(a => call(api_getAssetLabels, a.asset_id, {
                types: ["sku"],
                date_range: query.date_range,
            }))) : undefined
        ])

        const output = blocks.reduce((acc, curr, i) => {
            return { ...acc, [curr.block_id]: TimeSeriesFactory(outputSeries[i].map(d => ({
                time: new Date(d.starts_at),
                val: d.value,
                int: d.duration,
            }))) }
        }, {})
        const labels = options.withLabels ? assets.reduce((acc, curr, i) => {
            return {...acc, [curr.block_id]: labelsSeries[i].map(l => new Label(...parseLabelArguments(l)))}
        }, {}) : {}

        yield all([
            put(setBlockOutputData(output)),
            put(setBlockSkuLabels(labels))
        ])

        action.callback && action.callback({ oee: blocksOeeResource, labels: labels });
    } catch (error) {
        errorFlash(error);
    }
}

export function* getBlockOutputDataSaga() {
    yield takeLatest(
        K.ACTIONS.BLOCK_FETCH_OUTPUT_DATA,
        handleGetBlockPageOutput
    );
}

function* handleCreateBlockLabels(action) {
    try {
        const { assets, data } = action.payload;

        const promises = assets.map(assetId => call(api_createLabel,assetId, data))
        const result = yield all(promises)

        console.log(result)
        action.callback && action.callback()
    } catch (error) {
        errorFlash(error);
    }
}

export function* createBlockLabelsSaga() {
    yield takeLatest(
        K.ACTIONS.BLOCK_CREATE_LABELS,
        handleCreateBlockLabels
    );
}